$sidebar-margin-left: 180px;

@import '../../node_modules/vue2-datepicker/scss/index.scss';

.mx-datepicker-range {
  width: 100% !important;
}

.mx-datepicker-popup {
  .mx-btn-shortcut {
    word-break: break-word !important;
  }
}
